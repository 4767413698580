import React from 'react'
import styles from './step-list.module.sass'

export default function StepList(props) {
  const children = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      switch (child.type.displayName || child.type) {
        case 'List':
        case 'ul':
        case 'ol':
          return (
            <ul className={styles.stepList}>
              {child.props.children.map((step, index) => (
                <li className={styles.stepItem} key={`number-${index}`}>
                  <span className={styles.stepNumber}>0{index + 1}</span>
                  <span className={styles.stepText}>{step.props.children}</span>
                </li>
              ))}
            </ul>
          )

        default:
          return child
      }
    }
  })
  return children
}
