import React from 'react'
import styles from './aside-banner.module.sass'
import Image from '../Image'
import Link from '../Link'
import Markdown from 'markdown-to-jsx'
import { parameterize } from '../utils'

const FakeButton = ({ children }) => <span className={styles.fakeButton}>{children}</span>

export default function AsideBanner({ path, name, person, heading, content, cta = 'Get a Free Quote', children }) {
  return (
    <div className={styles.asideBanner + ' aside-banner'}>
      <Image
        wrapper
        className={styles.bannerImage}
        width={935}
        height={670}
        src={'/images/profiles/' + parameterize(person) + '/' + parameterize(person) + '.jpg'}
      />

      <Markdown className={styles.bannerHeading} options={{ wrapper: 'h3' }} children={heading || ''} />

      <div className={styles.bannerContent}>
        <h3>
          <Link to={path}><span dangerouslySetInnerHTML={{ __html: name }} /></Link>
        </h3>
        {children}
        {content && (
          <Markdown
            option={{
              wrapper: function({ children }) {
                return children
              }
            }}
          >
            {content}
          </Markdown>
        )}
        <FakeButton>{cta}</FakeButton>
      </div>

      {/* {content && (
        <Markdown className={styles.bannerContent} options={{ overrides: { FakeButton, Link } }}>
          {'<h3><Link to={' + path + '}>' + name + '</Link></h3>\n\n' + content + '\n\n<FakeButton>' + cta + '</FakeButton>'}
        </Markdown>
      )} */}
    </div>
  )
}
