import React, { useState, useEffect } from 'react'
import { isSpam } from '../../utils/tracking'
import { navigate } from 'gatsby'

import Image from '../Image'

import classNames from 'classnames'
import styles from './profile-contact.module.sass'

export default function ProfileContact({ person, images, classes }) {
  const [data, setData] = useState({ subject: 'SoftKraft enquiry' })
  const [formState, setFormState] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData({ ...data, path: localStorage.entry || window.location.path })
      // TODO here would be good to have some scroll trigger to see if the form was visible on the screen
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'form_start',
        formName: 'Profile',
        profile: person.name
      })
    }
  }, [])

  // useEffect(() => {
  //   console.log(data)
  //   console.log(person)
  // }, [data])

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const handleSubmit = e => {
    e.preventDefault()

    if (isSpam(data.message)) {
      navigate('/contact/submitted/')
    }
    const form = e.target
    setFormState('loading')

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        'form-name': form.getAttribute('name'),
        ...data,
        profile: person.name
      }).toString()
    })
      .then(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'form_sent',
          formName: form.getAttribute('name'),
          email: data.email,
          profile: person.name
        })
        localStorage.removeItem('entry')
        setFormState('success')
        navigate(form.getAttribute('action'))
      })
      .catch(error => setFormState('error'))
  }

  const handleInput = e => {
    handleChange(e)
    e.target.parentNode.classList.toggle('active', e.target.value)
  }

  const handleName = e => {
    setData({ ...data, subject: `SoftKraft enquiry from ${e.target.value}`, name: e.target.value })
    e.target.parentNode.classList.toggle('active', e.target.value)
  }

  return (
    <section className={classNames(styles.contactSection, classes)} id="contact-form">
      <div className="container">
        <div className={classNames(styles.profileBlock)}>
          <form
            id="profile-contact"
            name="Profile"
            method="post"
            action="/contact/thank-you/"
            data-netlify="true"
            // data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className={classNames(styles.profileContact, styles[formState])}
          >
            <p hidden>
              {/* <label>
                Don’t fill this out: <input name="bot-field" onChange={handleChange} />
              </label> */}
              <input type="hidden" name="form-name" value="Profile" />
              <input type="hidden" name="subject" />
              <input type="hidden" name="path" />
              <input type="hidden" name="profile" />
            </p>
            {formState === 'error' && <p>Sorry, something went wrong</p>}
            <div className={styles.formHeader}>
              {person.avatar && (
                <figure className={classNames(styles.profileAvatar)}>
                  <Image src={person.avatar} images={images} width={88} height={88} />
                </figure>
              )}
              <h3>Need a help with your&nbsp;project?</h3>
              <p>Let's talk! I am always ready to help</p>
            </div>
            <p className={classNames(styles.formField, 'short')}>
              <label htmlFor="name">Your name</label>
              <input type="text" name="name" id="name" required onChange={handleName} />
            </p>
            <p className={classNames(styles.formField, 'short')}>
              <label htmlFor={'email'}>Email</label>
              <input type={'email'} name={'email'} id={'email'} required required onChange={handleInput} />
            </p>
            <p className={styles.formField}>
              <label htmlFor={'message'}>Message</label>
              <textarea name={'message'} id={'message'} required rows={7} required onChange={handleInput} />
            </p>
            <p className={styles.formField}>
              <input type="checkbox" required id="privacy" />
              <label htmlFor="privacy">
                I consent to SofKraft processing the information submitted to respond to my enquiry.{' '}
                <a href="/privacy/" target="_blank">
                  Privacy Policy
                </a>
              </label>
            </p>
            {/* <p className={styles.formField}>
              <input type="checkbox" required name="rodo" id="rodo" />
              <label htmlFor="rodo">
                I consent to the{' '}
                <a href="/privacy/" target="_blank">
                  RODO Conditions
                </a>
              </label>
            </p> */}
            <p className={classNames(styles.formField, 'submit')}>
              <button disabled={formState === 'loading'} className={styles.formSubmit}>
                {formState === 'loading' ? 'Sending...' : 'Send'}
              </button>
            </p>
          </form>
        </div>
      </div>
    </section>
  )
}
