import React from 'react'
import Markdown from 'markdown-to-jsx'
import styles from './profile-section.module.sass'
import classNames from 'classnames'
import Button from '../Inlines/Button'
import Accent from '../Inlines/Accent'
import StepList from '../Elements/StepList'
import CheckList from '../Elements/CheckList'
import Image from '../Image'

export default function ProfileSection({ header, content, footer, classes, background }) {
  return (
    <section className={classNames(styles.profileSection, classes)}>
      <div className="container">
        {header && (
          <header className={styles.sectionHeader}>
            <Markdown children={header} options={{ overrides: { Accent } }} />
          </header>
        )}
        {content && (
          <Markdown
            className={styles.sectionContent}
            children={content}
            options={{ overrides: { Accent, Checks: { component: CheckList }, Steps: { component: StepList } } }}
          />
        )}
        {footer && (
          <div className={styles.sectionFooter}>
            <Markdown children={footer} options={{ overrides: { Button } }} />
          </div>
        )}
      </div>
      {background && <Image className={styles.sectionBg} src={background} wrapper />}
    </section>
  )
}
