import React from 'react'
import TestimonialGrid from '../Wrappers/TestimonialGrid'
import TestimonialCard from '../CardItems/TestimonialCard'
import classNames from 'classnames'
import styles from './profile-testimonials.module.sass'

export default function ProfileTestimonials({ testimonials, classes }) {
  return (
    <section className={classNames(styles.profileTestimonials, classes)}>
      <div className="container">
        <h2 className={styles.sectionTitle}>See that people I help are saying</h2>
        <div className={styles.sectionContent}>
          <TestimonialGrid>
            {testimonials.map((testimonial, i) => (
              <TestimonialCard key={i} {...testimonial} />
            ))}
          </TestimonialGrid>
        </div>
      </div>
    </section>
  )
}
