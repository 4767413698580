import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../layout'
import PageHead from '../layout/PageHead'
import PageHeader from '../components/Sections/PageHeader'
import ProfileHeader from '../components/Profiles/ProfileHeader'
import ProfilePosts from '../components/Profiles/ProfilePosts'
import ProfileSection from '../components/Profiles/ProfileSection'
import ProfileTestimonials from '../components/Profiles/ProfileTestimonials'
import ProfileContact from '../components/Profiles/ProfileContact'
import TextSection from '../components/Sections/TextSection'
// import FaqSection from '../components/Sections/FaqSection'
// import ImageSlider from '../components/Sections/ImageSlider'
import ServicesSection from '../components/Sections/ServicesSection'

export default function Profile({ data: { person, profile, sharedSections, images, posts, featuredPost } }) {
  const parseJson = ({ json, ...section }) => ({
    ...section,
    ...JSON.parse(json || '{}')
  })

  const sharedSectionsMap = {}
  sharedSections.nodes.forEach(({ name, section, json }) => {
    sharedSectionsMap[name] = json ? JSON.parse(json || '{}') : section
  })

  const applySharedSections = section => {
    const sharedSection = sharedSectionsMap[section.section]
    return sharedSection ? { ...sharedSection, ...section, type: sharedSection.type } : section
  }

  profile ||= {
    path: `/p/${person.id}/`,
    sections: [...(posts.nodes.length > 0 ? [{ type: 'profilePosts' }] : []), { type: 'profileContact' }]
  }

  const image = profile.image || person.image
  const sections = (profile.sections || []).map(parseJson).map(applySharedSections)

  return (
    <Layout>
      <PageHead title={person.name} subtitle={'Software Development Consulting'} path={profile.path} image={image} />
      <PageHeader dark={true} />
      <ProfileHeader content={profile.header} person={person} image={image} images={images} />
      {(sections || []).map(section => {
        switch (section.type) {
          case null:
          case 'profileSection':
            return <ProfileSection key={section.id} person={person} images={images} {...section} />
          case 'profilePosts':
            return <ProfilePosts key={section.id} allPosts={posts.nodes} featuredPost={featuredPost} {...section} />
          case 'profileTestimonials':
            return <ProfileTestimonials key={section.id} {...section} />
          case 'textSection':
            return <TextSection key={section.id} {...section} />
          case 'profileContact':
            return <ProfileContact key={section.id} {...section} person={person} images={images} />
          // case 'questions':
          //   return <FaqSection key={section.id} {...section} />
          // case 'imagesline':
          // case 'imageSlider':
          //   return <ImageSlider key={section.id} images={section.images} />
          case 'servicesSection':
            return <ServicesSection key={section.id} {...section} />
          default:
            return <div key={section.id}>{JSON.stringify(section)}</div>
        }
      })}
    </Layout>
  )
}

export const query = graphql`
  query Profile($path: String!, $directories: [String]!, $authorId: String!, $featuredPost: String) {
    profile: profilesYaml(path: { eq: $path }) {
      path
      header
      image
      sections {
        type
        json
      }
    }

    person: peopleYaml(id: { eq: $authorId }) {
      id
      name
      quote
      avatar
      position
      summary
      linkedin
      image
      bio
    }

    featuredPost: postsYaml(path: { eq: $featuredPost }) {
      title
      path
      thumb
      description
    }

    posts: allPostsYaml(filter: { path: { ne: $featuredPost }, authors: { elemMatch: { id: { eq: $authorId } } } }, sort: { fields: date, order: DESC }) {
      nodes {
        title
        path
        thumb
      }
    }

    sharedSections: allSectionsYaml {
      nodes {
        id
        name
        json
      }
    }

    images: allImageSharp(filter: { fields: { parentDir: { in: $directories } } }) {
      nodes {
        fluid(maxWidth: 540) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

Profile.propTypes = {
  data: PropTypes.shape({
    profile: PropTypes.shape({
      sections: PropTypes.array
    }).isRequired
  }).isRequired
}
