import React from 'react'
import Markdown from 'markdown-to-jsx'
import Image from '../Image'
import Button from '../Inlines/Button'
import Number from '../Inlines/Number'
import Link from '../Link'
import classNames from 'classnames'
import styles from './profile-header.module.sass'

const avatarPlaceholder =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='128' height='128'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='64' cy='64' r='64' fill='%23B8BAC0'/%3E%3Cpath fill='%23FFF' d='M64 96.019c-7.452 0-14.213-3.198-19.2-8.366-.005 3.097-.117 7.568-.938 10.83.538.329.956.87 1.038 1.542 1.065 8.662 9.275 15.194 19.1 15.194 9.823 0 18.033-6.533 19.1-15.194.083-.674.492-1.213 1.039-1.542-.822-3.262-.934-7.733-.938-10.83C78.213 92.82 71.45 96.019 64 96.019Z'/%3E%3Cpath fill='%23D7DBE0' d='M82.784 88.046C77.838 92.974 71.253 96.02 64 96.02c-7.452 0-14.213-3.198-19.2-8.366-.005 3.097-.117 7.568-.938 10.83.538.329.956.87 1.038 1.542.47 3.829 2.34 7.238 5.126 9.854 12.845-1.808 27.424-14.492 32.758-21.833Z'/%3E%3Cpath fill='%23FFF' d='M83.2 42.685H46.933c-5.881 0-10.666 4.786-10.666 10.667v12.8c0 16.469 12.441 29.867 27.733 29.867S91.733 82.62 91.733 66.152V51.219c0-4.705-3.827-8.534-8.533-8.534Z'/%3E%3Cpath fill='%235C546A' fill-rule='nonzero' d='M78.933 19.219H51.2c-4.013 0-7.385 2.783-8.294 6.52-7.344.933-13.04 7.22-13.04 14.813v10.667c0 5.498 2.335 10.627 6.4 14.272V53.352c0-5.881 4.786-10.667 10.667-10.667H83.2c4.706 0 8.533 3.828 8.533 8.534v14.27c4.067-3.645 6.4-8.774 6.4-14.27v-12.8c0-10.587-8.612-19.2-19.2-19.2Zm-62.498 87.593C28.15 119.818 45.118 128 64 128c18.882 0 35.85-8.182 47.565-21.188-2.043-2.762-4.923-5.006-8.627-6.043a1.998 1.998 0 0 0-.259-.055L85.65 98.16a3.974 3.974 0 0 0-.433-.024 2.133 2.133 0 0 0-2.117 1.873c-1.067 8.661-9.277 15.194-19.1 15.194-9.825 0-18.035-6.532-19.1-15.194-.133-1.101-1.125-1.93-2.17-1.872a1.819 1.819 0 0 0-.38.023l-17.03 2.555a2.008 2.008 0 0 0-.258.055c-3.704 1.038-6.584 3.281-8.627 6.043Z'/%3E%3C/g%3E%3C/svg%3E"

export default function ProfileHeader({ content, person, image, images }) {
  return (
    <section className={classNames(styles.profileHeader, { hasImage: image })}>
      <div className="container position-relative">
        <div className={styles.headerBlock}>
          {content && (
            <Markdown
              children={content}
              className={classNames(styles.headerContent, { hasImage: image })}
              options={{ forceWrapper: true, forceBlock: true, overrides: { Button, Number } }}
            />
          )}
          {!content && (
            <div className={classNames(styles.headerContent, { hasImage: image })}>
              <h1>{person.name}</h1>
              {person.position && <span className={styles.personPosition}>{person.position}</span>}
              {person.summary && <Markdown>{person.summary}</Markdown>}
              <Button customClass={styles.contactButton} href="#contact-form">
                Contact me
              </Button>
            </div>
          )}
          {image ? (
            <figure className={styles.headerImage}>
              <Image src={person.image} loading="eager" images={images} />
              {content && (
                <Button customClass={styles.contactButton} href="#contact-form">
                  Contact me
                </Button>
              )}
              {person.linkedin && (
                <Link className={styles.linkedinIcon} to={person.linkedin}>
                  LinkedIn
                </Link>
              )}
            </figure>
          ) : (
            <figure className={styles.headerAvatar}>
              <Image src={person.avatar || avatarPlaceholder} loading="eager" images={images} />
              {person.linkedin && (
                <Link className={styles.linkedinIcon} to={person.linkedin}>
                  LinkedIn
                </Link>
              )}
            </figure>
          )}
        </div>
      </div>
    </section>
  )
}
